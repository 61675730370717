<template>
    <div class="platform-speed-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="platform-speed-time-period">Time Period:</label>
                    <b-form-select id="platform-speed-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
                </b-form>
                <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                        <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                    </template>
                </date-range-picker>
            </div>
        </div>
        <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" show-empty>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <div class="row">
            <div class="col-12 text-right">
                <small>A count of executions under each bracket.</small>
            </div>
        </div>
    </div>
</template>

<script>
import { startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'platform-speed',
    data() {
        let date = new Date();

        return {
            tableOptions: {
                columns: [],
                items: []
            },
            dateRange: {
                startDate: startOfMonth(date),
                endDate: endOfMonth(date)
            },
            timePeriod: 'Daily',
            filterOptions: {
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    }
                ]
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            }
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('platformspeed', this.onPlatformSpeed);
    },
    components: {
        DateRangePicker
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('platformspeed', this.onPlatformSpeed);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'PlatformSpeed',
                args: {
                    timePeriod: this.timePeriod,
                    startDate: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate: format(this.dateRange.endDate, 'yyyy-MM-dd')
                }
            });
        },
        onPlatformSpeed(event) {
            let columns = [
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    sortable: true,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy');
                    }
                }
            ];

            let that = this;

            let rows = [];
            let first = true;
            for(let date in event.response.data) {
                let row = {
                    timestamp: new Date(date)
                };

                for(let key in event.response.data[date]) {
                    row[key] = event.response.data[date][key];
                }

                if(first) {
                    for(let key in row) {
                        if(key === 'timestamp') {
                            continue;
                        }

                        columns.push({
                            key: key,
                            label: key,
                            sortable: true,
                            formatter: function(value) {
                                return that.$options.filters.number(value, 0);
                            }
                        });
                    }

                    first = false;
                }

                rows.push(row);
            }

            this.tableOptions.columns = columns;
            this.tableOptions.items = rows;
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.tableOptions.items, clone);
            for(let item of items) {
                item.timestamp = format(item.timestamp, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'PlatformSpeed');
        }
    }
}
</script>