<template>
    <div class="today-platform-speed-widget">
        <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" show-empty sort-by="Duration" :sort-desc="true">
            <template slot="empty">
                No results found
            </template>
        </b-table>
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'today-platform-speed',
    data() {
        return {
            tableOptions: {
                columns: [
                    {
                        key: 'Timestamp',
                        label: 'Timestamp',
                        sortable: true,
                        formatter: function(value) {
                            return format(value, 'MMM dd, yyyy HH:mm:ss.sss');
                        }
                    },
                    {
                        key: 'Duration',
                        label: 'Duration',
                        sortable: true
                    },
                    {
                        key: 'Count',
                        label: 'Count',
                        sortable: true
                    }
                ],
                items: []
            }
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('todayplatformspeed', this.onTodayPlatformSpeed);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('todayplatformspeed', this.onTodayPlatformSpeed);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'TodayPlatformSpeed',
                args: {}
            });
        },
        onTodayPlatformSpeed(event) {
            for(let result of event.response) {
                result.Timestamp = new Date(result.Timestamp);
            }

            this.tableOptions.items = event.response;
        }
    }
}
</script>