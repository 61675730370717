<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Platform Speed">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Today's slowest executions</h4>
                        </div>
                        <div class="card-body">
                            <today-platform-speed></today-platform-speed>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Platform speed</h4>
                        </div>
                        <div class="card-body">
                            <platform-speed></platform-speed>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import TodayPlatformSpeed from '@/widgets/TodayPlatformSpeed';
import PlatformSpeed from '@/widgets/PlatformSpeed';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        TodayPlatformSpeed,
        PlatformSpeed
    }
}
</script>